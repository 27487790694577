<script>
import $ from 'jquery';

export default {
    emits: ['update:modelValue', 'select', 'unselect', 'close'],
    props: {
        select2Method: {
            type: String,
            default: 'jix_select2',
        },
        config: {
            type: Object,
            default() {
                return {};
            },
        },
        modelValue: {
            type: [String, Number, Array],
            default: null,
        }
    },

    data() {
        return {
            ignoreChanges: false,
        };
    },

    mounted() {
        $(this.$el)[this.select2Method](this.config)
            .val(this.modelValue)
            .trigger('change')
            .on('change', () => {
                if (!this.ignoreChanges) {
                    this.$emit('update:modelValue', $(this.$el).val());
                }
            })
            .on('select2:select', (event) => {
                if (!this.ignoreChanges) {
                    this.$emit('select', event.params.data);
                }
            })
            .on('select2:unselect', () => {
                if (!this.ignoreChanges) {
                    this.$emit('unselect');
                }
            })
            .on('select2:close', () => {
                if (!this.ignoreChanges) {
                    this.$emit('close');
                }
            });
    },

    unmounted() {
        $(this.$el).off().select2('destroy');
    },

    watch: {
        modelValue: {
            deep: true,
            handler() {
                this.syncValue();
            },
        },
        config(config) {
            $(this.$el).empty()[this.select2Method](config);
        },
    },

    methods: {
        syncValue() {
            this.ignoreChanges = true;
            $(this.$el)
                .val(this.modelValue)
                .trigger('change');
            this.ignoreChanges = false;
        },
        /**
         * Focus the select2 and open the dropdown.
         */
        open() {
            $(this.$el).select2('open');
        },
        /**
         * Close the dropdown.
         */
        close() {
            $(this.$el).select2('close');
        },
        /**
         * Focus the select2 without opening the dropdown.
         */
        focus() {
            // this.$el.focus() opens the dropdown.
            // $(this.$el).select2('focus') makes it look like it is focused,
            // but keyboard focus is somewhere else.
            // This does the job:
            this.open();
            this.close();
        },
    },
};
</script>

<template>
    <select>
        <slot />
    </select>
</template>
