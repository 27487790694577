import { api } from 'JIX/utils.js';
import { __ } from 'JIX/gettext.js';

/**
 * Requests and returns user location
 * Returns a Promise resolving to a GeolocationCoordinates object.
 */
export function getCurrentPosition() {
    return new Promise((resolve, reject) => {
        if ("geolocation" in navigator) {
            const opts = { timeout: 10000, maximumAge: 15 * 60 * 1000, enableHighAccuracy: false };
            const success = (res) => {
                const pos = { lat: res.coords.latitude, lng: res.coords.longitude };
                resolve(pos);
            };
            const error = (err) => {
                let message;
                if (err.code) {
                    switch (err.code) {
                        case err.PERMISSION_DENIED:
                        case err.TIMEOUT:
                            message = __('For at bruge "Nær placering" skal du godkende at vi må se din placering.');
                            break;
                        case err.POSITION_UNAVAILABLE:
                            message = __('Placering kunne ikke findes.');
                            break;
                    }
                }
                reject(new Error(message || err.message || __('Ukendt fejl')));
            };
            navigator.geolocation.getCurrentPosition(success, error, opts);
        } else {
            throw new Error(__("Browseren understøtter ikke geografisk placering."));
        }
    });
}

/**
 * Returns coordinates for an address through DAWA.
 * Returns a Promise resolving to a LngLat-like object with address.
 */
export function geocodeDawa(address) {
    const DAWA_GEOCODE_API_URL = 'https://api.dataforsyningen.dk/adgangsadresser';
    const params = {
        q: address,
        struktur: 'mini',
    };
    return api('GET', DAWA_GEOCODE_API_URL, params)
        .then(res => {
            if (res.length) {
                return {
                    lng: res[0].x,
                    lat: res[0].y,
                    address: res[0].betegnelse,
                    dawaAddressId: res[0].id,
                };
            } else {
                throw new Error("Failed to find address in DAWA");
            }
        });
}

/**
 * Returns an address from coordinates through DAWA.
 * Returns a Promise resolving to a LngLat-like object with address field
 */
export function reverseGeocodeDawa(pos) {
    const DAWA_REVERSE_GEOCODE_API_URL = 'https://api.dataforsyningen.dk/adgangsadresser/reverse';
    const params = {
        x: pos.lng,
        y: pos.lat,
        struktur: 'mini',
    };
    return api('GET', DAWA_REVERSE_GEOCODE_API_URL, params)
        .then(res => {
            return {
                ...pos,
                address: res.betegnelse,
                dawaAddressId: res.id,
            };
        });
}

export function reverseGeocodeJIX(pos) {
    const url = `/api/address/v1/reverse-geocode/${pos.lng}/${pos.lat}`;
    return api('GET', url)
        .then(res => {
            return {
                address: res.address,
                lng: res.location.x,
                lat: res.location.y,
            };
        });
}

/**
 * Returns a Promise always reolving to a LngLat-like objects with address field
 */
export function reverseGeocodeFallback(pos) {
    return Promise.resolve({
        ...pos,
        address: `${pos.lat}, ${pos.lng}`
    });
}

/**
 * Returns user location.
 * Fecthes cached address if set otherwise requests and caches user location
 */
export function getGeolocation(reverseGeocodeService) {
    const cached = window.sessionStorage.getItem('jix-user-pos');
    if (cached) {
        return Promise.resolve(JSON.parse(cached));
    } else {
        return getCurrentPosition()
            .then(reverseGeocodeService)
            .then(pos => {
                window.sessionStorage.setItem('jix-user-pos', JSON.stringify(pos));
                return pos;
            });
    }
}
